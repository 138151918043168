<template>
  <p
    class="mt-5 medium-p d-flex justify-end">
    <router-link
      data-test="forgot_your_password_button"
      :to="{ name: 'reset-password' }">
      Forgot your password?
    </router-link>
  </p>
</template>
<script>
export default {
  name: 'ForgotPassword',
};
</script>
